<template>
  <router-view />
  <el-backtop
    :bottom="80"
    :right="10" />
</template>

<script>

export default {
  name: 'App'
}
</script>
