import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { hasPermission } from '@/store/modules/permission'
import { getToken } from '@/utils/userAuth'
import { resolveHome } from '@/utils/permission'

NProgress.configure({ showSpinner: false })

const noRedirectPaths = ['denied', 'registration', 'login', 'logout', '500', '401']

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  if (noRedirectPaths.includes(to.name)) {
    return next()
  }

  if (getToken()) {
    if (store.getters.roles.length === 0) {
      try {
        const { roles, permissions } = await store.dispatch('user/GetUserInfo')
        if (roles.length !== 0 && permissions.length !== 0) {
          if (to.query.redirect) {
            return next(resolveRedirect(to.query.redirect))
          }
          if (to.path === '/') {
            return next({ name: resolveHome() })
          }
        } else {
          return next({ name: 'registration' })
        }
      } catch (e) {
        console.log(e)
      }
    }

    let permissions = [
      to.matched.map(x => x.meta?.permissions ?? []),
      (to.meta?.permissions ?? [])
    ]
    permissions = [...new Set(permissions.flat(2))]

    const isPermission = hasPermission(store.getters.permissions, permissions)

    if (isPermission) {
      return next()
    } else {
      console.warn('401', store.getters.permissions, permissions)
      return next({ name: 'registration' })
    }
  }
  return next({ name: 'registration', query: { redirect: to.path } })
})

router.afterEach(() => {
  NProgress.done()
})

export const resolveRedirect = (redirect) => {
  return redirect !== '/home' ? { path: redirect } : { name: resolveHome() }
}
