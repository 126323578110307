import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/layout/Layout'

import {
  REPORT_VIEW
} from '@/services/permissions/PermissionService'
import administration from '@/router/administration'
import journals from '@/router/journals'
import personal from '@/router/personal'

export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/access/login.vue'),
    hidden: true
  },
  {
    path: '/registration',
    component: () => import('@/views/layout/RegistrationLayout'),
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/access/registration/index'),
        name: 'registration',
        meta: { title: 'Регистрация', icon: 'empty', affix: true }
      }
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/access/logout.vue'),
    hidden: true
  },
  {
    path: '/denied',
    name: 'denied',
    component: () => import(/* webpackChunkName: "denied" */ '@/views/access/denied.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404Page" */ '@/views/errorPage/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: '401',
    component: () => import(/* webpackChunkName: "401Page" */ '@/views/errorPage/401.vue'),
    hidden: true
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "500Page" */ '@/views/errorPage/500.vue'),
    hidden: true
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "500Page" */ '@/views/errorPage/service.vue'),
    hidden: true
  },
  journals,
  {
    path: '/reports',
    component: Layout,
    meta: {
      permissions: [
        REPORT_VIEW
      ]
    },
    children: [
      {
        path: '/reports',
        component: () => import('@/views/reportsPage'),
        name: 'reportsPage',
        meta: {
          title: 'Отчеты'
        }
      }
    ]
  },
  administration,
  personal,
  {
    path: '',
    redirect: '/journals',
    hidden: true
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    hidden: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRouterMap
})

export default router
