import request from '@/utils/request'

export function storeUser (data) {
  return request({
    url: '/api/users',
    method: 'post',
    data
  })
}

export function updateUser (data, id) {
  return request({
    url: `/api/users/${id}`,
    method: 'put',
    data
  })
}

export function showUser (id) {
  return request({
    url: `/api/users/${id}`,
    method: 'get'
  })
}

export function indexUser (params) {
  return request({
    url: '/api/users',
    method: 'get',
    params
  })
}

export function updateUsers (params) {
  return request({
    url: '/api/users/mass_update',
    method: 'post',
    params
  })
}

export function massApproveUserRegistration (params) {
  return request({
    url: '/api/users/mass-approve-registration',
    method: 'put',
    params
  })
}

export function massDeclineUserRegistration (params) {
  return request({
    url: '/api/users/mass-decline-registration',
    method: 'put',
    params
  })
}

export function declineUserRegistration (id, declineReason) {
  return request({
    url: `/api/user/${id}/decline-registration`,
    method: 'put',
    data: { decline_reason: declineReason }
  })
}

export function approveUserRegistration (id, params) {
  return request({
    url: `/api/user/${id}/approve-registration`,
    method: 'put',
    params
  })
}

// --Публичная часть
export function login (email, password) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data: {
      email, password
    }
  })
}

export function applyForPasswordReset (email) {
  return request({
    url: '/api/auth/apply-for-password-reset',
    method: 'post',
    data: {
      email
    }
  })
}

export function confirmPasswordReset (email, confirmCode) {
  return request({
    url: '/api/auth/confirm-password-reset',
    method: 'post',
    data: {
      email,
      confirm_code: confirmCode
    }
  })
}

export function regenerateAccessToken () {
  return request({
    url: '/api/auth/regenerate-access-token',
    method: 'post'
  })
}

export function logout () {
  return request({
    url: '/api/auth/logout',
    method: 'post'
  })
}

export function register (data) {
  return request({
    url: '/api/auth/register',
    method: 'post',
    data
  })
}

export function changePassword (data) {
  return request({
    url: '/api/user/change-password',
    method: 'post',
    data
  })
}
