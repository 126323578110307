import request from '@/utils/request'

export function showIogv (id) {
  return request({
    url: `/api/iogvs/${id}`,
    method: 'get'
  })
}

export function indexIogv (params) {
  return request({
    url: '/api/iogvs',
    method: 'get',
    params
  })
}

export function indexPublicIogvs (params) {
  return request({
    url: '/api/public/iogvs',
    method: 'get',
    params
  })
}

export function indexPublicSuborgs (params) {
  return request({
    url: '/api/public/subordinate-orgs',
    method: 'get',
    params
  })
}

export function storeIogv (data) {
  return request({
    url: '/api/iogvs',
    method: 'post',
    data
  })
}

export function updateIogv (data, id) {
  console.log(data)
  return request({
    url: `/api/iogvs/${id}`,
    method: 'put',
    data
  })
}

export function getNextIogvCode () {
  return request({
    url: '/api/iogvs/get-next-iogv-code',
    method: 'get'
  })
}
