// Карточка План КНМ
export const PLAN_KNM_FULL_ACCESS = 'plan_knm_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const PLAN_KNM_READ = 'plan_knm_read' // Чтение всех План КНМ
export const PLAN_KNM_CREATE = 'plan_knm_create' // Создание План КНМ
export const PLAN_KNM_EDIT_ORG = 'plan_knm_edit_org' // Редактирование План КНМ которые принадлежат к его ИОГВ. Даже если создавал не он
export const PLAN_KNM_EDIT_RESP_IOGV = 'plan_knm_edit_resp_iogv' // Редактирование План КНМ только своих (*Кто создал, т.е. автор карточки)
export const PLAN_KNM_DELETE_RESP_IOGV = 'plan_knm_delete_resp_iogv' // Удаление План КНМ только своих (*Кто создал, т.е. автор карточки)
export const PLAN_KNM_DELETE = 'plan_knm_delete'
export const PLAN_KNM_RESTORE = 'plan_knm_restore'
export const PLAN_KNM_RESTORE_RESP_IOGV = 'plan_knm_restore_resp_iogv'

export const permissions = {
  create: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_CREATE],
  edit: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_EDIT_ORG, PLAN_KNM_EDIT_RESP_IOGV],
  delete: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_DELETE_RESP_IOGV, PLAN_KNM_DELETE],
  restore: [PLAN_KNM_FULL_ACCESS, PLAN_KNM_RESTORE, PLAN_KNM_RESTORE_RESP_IOGV]
}
