// Страница администрации пользователей / журнал пользователей
export const USER_ADMINISTRATION_FULL_ACCESS = 'users_full_access'
export const USER_ADMINISTRATION_CREATE = 'users_create'
export const USER_ADMINISTRATION_READ = 'users_read'
export const USER_ADMINISTRATION_DELETE = 'users_delete'
export const USER_ADMINISTRATION_RESTORE = 'users_restore'

export const permissions = {
  create: [USER_ADMINISTRATION_FULL_ACCESS, USER_ADMINISTRATION_CREATE],
  edit: [USER_ADMINISTRATION_FULL_ACCESS],
  delete: [USER_ADMINISTRATION_FULL_ACCESS, USER_ADMINISTRATION_DELETE],
  restore: [USER_ADMINISTRATION_FULL_ACCESS, USER_ADMINISTRATION_RESTORE]
}
