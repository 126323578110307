import { Roles } from '@/services/roles'

export const rolesOptions = () => {
  return [
    {
      value: Roles.ROLE_OPERATOR_IOGV,
      label: 'Сотрудник ИОГВ'
    },
    {
      value: Roles.ROLE_OPERATOR_RESP_IOGV,
      label: 'Сотрудник Ответственного ИОГВ'
    },
    {
      value: Roles.ROLE_OPERATOR_PO,
      label: 'Сотрудник ПО'
    },
    {
      value: Roles.ROLE_ADMIN,
      label: 'Администратор'
    }
  ]
}
