import { RouterView } from 'vue-router'
import { ANYTHING } from '@/services/permissions/PermissionService'

import { industryAffiliation } from './industryAffiliation'
import { knmType } from './knmType'

export const dictionaries = {
  path: 'dictionaries',
  component: RouterView,
  name: 'dictionariesWrap',
  redirect: '/administration/dictionaries/industry_affiliation/list',
  meta: {
    title: 'Справочники',
    permissions: [
      ANYTHING
    ]
  },
  children: [
    industryAffiliation,
    knmType
  ]
}
