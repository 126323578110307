// Карточка Запрос по КНМ
export const KNM_REQUEST_FULL_ACCESS = 'knm_request_full_access'
export const KNM_REQUEST_READ = 'knm_request_read'
export const KNM_REQUEST_CREATE = 'knm_request_create'
export const KNM_REQUEST_EDIT = 'knm_request_edit'
export const permissions = {
  create: [KNM_REQUEST_FULL_ACCESS, KNM_REQUEST_CREATE],
  read: [KNM_REQUEST_FULL_ACCESS, KNM_REQUEST_READ],
  edit: [KNM_REQUEST_FULL_ACCESS, KNM_REQUEST_EDIT],
  delete: [KNM_REQUEST_FULL_ACCESS]
}
