// Карточка КНМ
export const KNM_FULL_ACCESS = 'knm_full_access' // Полный доступ: чтение, создание, редактирование, удаление
// export const KNM_READ = 'knm_read' // Чтение всех КНМ
export const KNM_CREATE = 'knm_create' // Создание КНМ
export const KNM_EDIT_ORG = 'knm_edit_org' // Редактирование КНМ которые принадлежат к его ИОГВ. Даже если создавал не он
export const KNM_EDIT_RESP_IOGV = 'knm_edit_resp_iogv' // Редактирование КНМ только своих (*Кто создал, т.е. автор карточки)
export const KNM_DELETE_RESP_IOGV = 'knm_delete_resp_iogv' // Удаление КНМ только своих (*Кто создал, т.е. автор карточки)
export const KNM_DELETE = 'knm_delete'
export const KNM_RESTORE = 'knm_restore'
export const KNM_RESTORE_RESP_IOGV = 'knm_restore_resp_iogv'

export const permissions = {
  create: [KNM_FULL_ACCESS, KNM_CREATE],
  edit: [KNM_FULL_ACCESS, KNM_EDIT_ORG, KNM_EDIT_RESP_IOGV],
  delete: [KNM_FULL_ACCESS, KNM_DELETE_RESP_IOGV, KNM_DELETE],
  restore: [KNM_FULL_ACCESS, KNM_RESTORE, KNM_RESTORE_RESP_IOGV]
}
