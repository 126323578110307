export function getDateInYmdFormat (dateInString) {
  const d = new Date(dateInString)

  return d.getFullYear() +
    '-' + ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' + ('0' + d.getDate()).slice(-2)
}

export function getDateInLocaleFormat (dateInString) {
  const d = new Date(dateInString)

  return ('0' + d.getDate()).slice(-2) +
    '.' + ('0' + (d.getMonth() + 1)).slice(-2) +
    '.' + d.getFullYear()
}

export function getDateTimeInLocaleFormat (dateInString) {
  const d = new Date(dateInString)

  return getDateInLocaleFormat(dateInString) + ' ' +
    ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
}

export function timestampMillisecondsToSeconds (timestampMilliseconds) {
  return Math.floor(timestampMilliseconds / 1000)
}

export function getTimestampInSeconds () {
  return timestampMillisecondsToSeconds(Date.now())
}
