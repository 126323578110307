<template>
  <el-dropdown
    size="large"
    class="new-profile-widget">
    <el-button
      size="large"
      plain>
      Меню
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="profileDrawer = !profileDrawer">
          Профиль "{{ currentUserFio() }}"
        </el-dropdown-item>
        <el-dropdown-item @click="helpDrawer = !helpDrawer">
          Помощь
        </el-dropdown-item>
        <el-dropdown-item
          divided
          @click="logout">
          Выход
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <help-info-drawer v-model="helpDrawer" />
  <el-drawer
    v-model="profileDrawer"
    title="Мой профиль"
    direction="rtl">
    <el-form
      ref="form"
      :disabled="true"
      :model="form"
      label-width="150px">
      <el-form-item
        label="Фамилия"
        prop="surname">
        <el-input
          v-model="form.surname"
          disabled />
      </el-form-item>

      <el-form-item
        label="Имя"
        prop="name">
        <el-input
          v-model="form.name"
          disabled />
      </el-form-item>

      <el-form-item
        label="Отчество"
        prop="patronymic">
        <el-input
          v-model="form.patronymic"
          disabled />
      </el-form-item>

      <el-form-item
        label="Телефон"
        prop="phone">
        <el-input
          v-model="form.phone"
          disabled />
      </el-form-item>

      <el-form-item
        label="Электронная почта"
        prop="email">
        <el-input
          v-model="form.email"
          disabled />
      </el-form-item>

      <el-form-item
        label="Роли"
        prop="roles">
        <el-select
          v-model="form.roles"
          disabled
          multiple>
          <el-option
            v-for="item in rolesOptions()"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item
        v-show="isIogvTypeOn"
        prop="iogv_id"
        class="item-iogv_id-wrapper"
        label="Организация">
        <el-select
          v-model="form.iogv_id"
          placeholder="Выберите из списка">
          <el-option
            v-for="item in selectedOrgIogvs"
            :key="item.id"
            :label="item.short_name"
            :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="isSuborgTypeOn"
        prop="suborg_id"
        class="item-suborg_id-wrapper"
        label="Организация">
        <el-select
          v-model="form.suborg_id"
          placeholder="Выберите из списка">
          <el-option
            v-for="item in selectedOrgSuborgs"
            :key="item.id"
            :label="item.short_name"
            :value="item.id" />
        </el-select>
      </el-form-item>

      <el-form-item
        label="ОГРН"
        prop="ogrn">
        <el-input
          v-model="form.ogrn"
          disabled />
      </el-form-item>

      <el-form-item
        label="Должность"
        prop="position">
        <el-input
          v-model="form.position"
          disabled />
      </el-form-item>

      <el-form-item
        label="Подразделение"
        prop="department">
        <el-input
          v-model="form.department"
          disabled />
      </el-form-item>

      <el-form-item
        label="Подразделение (полное)"
        prop="department_full">
        <el-input
          v-model="form.department_full"
          disabled />
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import { required } from '@/utils/rules'
import { showUser } from '@/api/user'
import { rolesOptions } from '@/views/administration/users/userRoles'
import { EmployeeAffiliationIogv } from '@/utils/consts'
import UserFormMixin from '@/views/administration/users/mixins/UserFormMixin'
import HelpInfoDrawer from '@/views/components/HelpInfoDrawer.vue'

export default {
  name: 'ProfileButton',
  components: { HelpInfoDrawer },
  mixins: [UserFormMixin],
  data () {
    return {
      profileDrawer: false,
      helpDrawer: false,

      form: {
        surname: null,
        name: null,
        patronymic: null,
        phone: null,
        position: null,
        department: null,
        department_full: null,
        org_type: EmployeeAffiliationIogv,
        ogrn: null,
        email: null
      },

      rules: {
        surname: [required],
        name: [required],
        lastname: [required],
        position: [required],
        department: [required],
        department_full: [required],
        email: [required],
        ogrn: [
          required,
          {
            type: 'string',
            message: 'В ОГРН должно быть ровно 13 символов',
            min: 13,
            max: 13
          }
        ]
      }
    }
  },
  created () {
    showUser(this.$store.state.user.id).then(({ data }) => {
      this.loadData(data)
    })
  },
  methods: {
    rolesOptions,
    currentUserFio () {
      return this.$store.state.user.profile.fio
    },
    loadData (data) {
      this.form = data
      this.updateIogvSuborgSelects(data)
    },
    logout () {
      this.$store.dispatch('user/FedLogout').then(() => {
        // Перенаправляем бязательно с перезагрузкой иначе стили другого шаблона не сбросятся!
        window.location.href = '/registration'
      })
    }
  }
}
</script>

<style scoped>
.new-profile-widget {
  margin-inline: 3ch;

  .el-button.is-text:not(.is-disabled):focus-visible {
    outline: none !important;
  }
}
</style>
