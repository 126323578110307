<template>
  <div
    v-if="!hamburgerHidden"
    class="navbar">
    <div>
      <hamburger
        :toggle-click="toggleSideBar"
        :is-active="sidebar.opened"
        class="hamburger-container" />
      <UseDark>
        <el-switch
          v-model="theme"
          style="margin-left: 1.5vw"
          @click="toggleDarkClick" />
      </UseDark>
      <breadcrumb class="breadcrumb-container" />
    </div>
    <profile-button v-if="isUserInfoLoaded" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'
import { UseDark } from '@vueuse/components'
import { useDark, useToggle } from '@vueuse/core'
import ProfileButton from '@/views/layout/components/ProfileButton.vue'

export default {
  name: 'NavbarComponent',
  components: {
    ProfileButton,
    Hamburger,
    Breadcrumb,
    UseDark
  },
  props: {
    hamburgerHidden: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      theme: useDark(),
      toggleDark: useToggle(this.theme)
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'device']),
    isUserInfoLoaded () {
      return this.$store.state.user.id > 0
    }
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    toggleDarkClick () {
      if (this.theme) {
        this.theme = true
        return
      }
      if (!this.theme) {
        this.theme = false
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.nav {
  margin-left: 20px;
  font-size: 14px;
}

.s-i {
  float: right;
  font-size: 14px;
  color: grey;
  margin-right: 10px;
}
html.dark .navbar {
  background: #1d1e1f;
  border-bottom: 1px solid #414243;
}

.navbar {
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  border-radius: 0 !important;
  position: sticky;
  top: 0;
  width: 99%;
  z-index: 1999;
  background: white;
  border-bottom: 1px solid #ebeef5;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }

    .international {
      vertical-align: top;
    }
  }
}
</style>
