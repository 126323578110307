import { isInteger } from 'lodash'

export const patronymicValidatorFunc = (_this) => {
  return {
    required: !_this?.isNoPatronymicOn,
    validator (rule, value, callback, source, options) {
      if (_this.isNoPatronymicOn) {
        return true
      }
      return value != null && value.trim().length > 0
    },
    message: 'Введите отчество',
    trigger: ['blur', 'change']
  }
}

export const departmentValidatorFunc = (_this) => {
  return {
    required: !_this?.isOrganizationHeadOn,
    message: 'Введите подразделение',
    trigger: ['blur', 'change']
  }
}

export const orgIogvValidatorFunc = (_this) => {
  return {
    validator (rule, value, callback, source, options) {
      if (!_this.isIogvTypeOn) {
        return true
      }

      return isInteger(value)
    },
    message: 'Выберите организацию',
    trigger: ['blur', 'change']
  }
}

export const orgSuborgValidatorFunc = (_this) => {
  return {
    validator (rule, value, callback, source, options) {
      if (!_this.isSuborgTypeOn) {
        return true
      }

      return isInteger(value)
    },
    message: 'Выберите организацию',
    trigger: ['blur', 'change']
  }
}

export const fieldIsRequired = { required: true, trigger: ['blur', 'change'], message: 'Поле обязательно для заполнения' }
