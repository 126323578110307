import { EmployeeAffiliationIogv, EmployeeAffiliationSuborg } from '@/utils/consts'
import { indexPublicIogvs, indexPublicSuborgs } from '@/api/iogv'
import {
  fieldIsRequired,
  departmentValidatorFunc,
  orgIogvValidatorFunc,
  orgSuborgValidatorFunc,
  patronymicValidatorFunc
} from '@/views/administration/users/formRules'

export default {
  name: 'UserFormMixin',
  data () {
    return {
      orgIogvsLoading: false,
      orgSuborgsLoading: false,
      orgIogvsListQuery: {
        page: 1,
        perPage: 10,
        prop: 'short_name',
        order: 'asc',
        quickSearch: ''
      },
      orgSuborgsListQuery: {
        page: 1,
        perPage: 10,
        prop: 'short_name',
        order: 'asc',
        quickSearch: ''
      },
      // Отфильтрованные списки для показа
      selectedOrgIogvs: [],
      selectedOrgSuborgs: [],
      rules: {
        ...this.getUserFormMixinRules()
      }
    }
  },

  computed: {
    isNoPatronymicOn () {
      return !!this.form.no_patronymic === true
    },
    isOrganizationHeadOn () {
      return !!this.form.is_organization_head === true
    },
    isIogvTypeOn () {
      return this.form.org_type === EmployeeAffiliationIogv
    },
    isSuborgTypeOn () {
      return this.form.org_type === EmployeeAffiliationSuborg
    }
  },
  methods: {
    showOrgIogvsLoading (value) {
      this.orgIogvsLoading = value
    },
    showOrgSuborgsLoading (value) {
      this.orgSuborgsLoading = value
    },
    getOrgIogvsRemoteMethod (query = '') {
      this.showOrgIogvsLoading(true)

      this.orgIogvsListQuery.quickSearch = query
      indexPublicIogvs(this.orgIogvsListQuery).then(response => {
        this.selectedOrgIogvs = response.data.data.filter(item => {
          return item.short_name.toLowerCase().indexOf(query.toLowerCase()) > -1
        })

        this.showOrgIogvsLoading(false)
      })
    },
    getOrgSuborgsRemoteMethod (query = '') {
      this.showOrgSuborgsLoading(true)

      this.orgSuborgsListQuery.quickSearch = query
      indexPublicSuborgs(this.orgSuborgsListQuery).then(response => {
        this.selectedOrgSuborgs = response.data.data.filter(item => {
          return item.short_name.toLowerCase().indexOf(query.toLowerCase()) > -1
        })

        this.showOrgSuborgsLoading(false)
      })
    },
    handleOrgIogvChanged (id) {
      this.form.ogrn = this.selectedOrgIogvs.find(x => x.id === id)?.ogrn
    },
    handleOrgSuborgChanged (id) {
      this.form.ogrn = this.selectedOrgSuborgs.find(x => x.id === id)?.ogrn
    },
    updateIogvSuborgSelects (data) {
      if (this.form.org_type === EmployeeAffiliationIogv) {
        this.selectedOrgIogvs = [data.iogvSubOrg]
        // Приходится делать т.к. иначе select не переотрисуется
        this.form.iogv_id = data.iogvSubOrg.id
      } else if (this.form.org_type === EmployeeAffiliationSuborg) {
        this.selectedOrgSuborgs = [data.iogvSubOrg]
        // Приходится делать т.к. иначе select не переотрисуется
        this.form.suborg_id = data.iogvSubOrg.id
      }
    },
    handleNoPatronymicChange (isChecked) {
      if (isChecked) {
        this.form.patronymic = ''
      }
    },
    getUserFormMixinRules (_this) {
      return {
        surname: [fieldIsRequired],
        name: [fieldIsRequired],
        patronymic: [patronymicValidatorFunc(_this)],
        iogv_id: [orgIogvValidatorFunc(_this)],
        suborg_id: [orgSuborgValidatorFunc(_this)],
        position: [fieldIsRequired],
        department: [departmentValidatorFunc(_this)],
        department_full: [departmentValidatorFunc(_this)]
      }
    }
  }
}
