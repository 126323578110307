import { hasPermission } from '@/store/modules/permission'
import {
  getToken,
  setToken,
  removeToken,
  getTokenExpiration,
  setTokenExpiration,
  removeTokenExpiration,
  setTokenExpiredAt,
  getTokenExpiredAt,
  removeTokenExpiredAt
} from '@/utils/userAuth'
import { login, regenerateAccessToken, applyForPasswordReset, confirmPasswordReset } from '@/api/user'
import permissionService from '@/services/permissions/PermissionService'
import { authenticatedUserChecksPassedEvent } from '@/utils/customEvents'

const SettingsKey = 'user-settings'

const user = {
  namespaced: true,

  state: {
    token: getToken(),
    token_expiration: getTokenExpiration(),
    token_expired_at: getTokenExpiredAt(),
    token_expiration_notify_before_sec: 600, // 10 минут
    id: null,
    profile: {
      fio: ''
    },
    settings: {},
    roles: [],
    permissions: [],
    isPanelInit: false,
    needManuallyChangePassword: false,
    panelInitError: false
  },

  getters: {
    hasRole: state => roles => hasPermission(state.roles, roles),
    hasPermission: state => permissions => hasPermission(state.permissions, permissions)
  },

  mutations: {
    SET_PROFILE_FIO: (state, profileFio) => {
      state.profile.fio = profileFio || 'Не задано'
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_TOKEN_EXPIRATION: (state, expiration) => {
      state.token_expiration = expiration
    },
    SET_TOKEN_EXPIRED_AT: (state, expiredAt) => {
      state.token_expired_at = expiredAt
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_NEED_MANUALLY_CHANGE_PASSWORD: (state, value) => {
      state.needManuallyChangePassword = value
    },
    SET_PANEL_STATE: (state, bool) => {
      state.isPanelInit = bool
    },
    SET_PANEL_INIT_ERROR: (state, bool) => {
      state.panelInitError = bool
    }
  },

  actions: {
    // user login
    login ({ commit }, userInfo) {
      const { email, password } = userInfo
      return new Promise((resolve, reject) => {
        login(email.trim(), password.trim()).then(response => {
          commit('SET_TOKEN', response.data.token)
          setToken(response.data.token)
          commit('SET_TOKEN_EXPIRATION', response.data.expiration)
          setTokenExpiration(response.data.expiration)
          commit('SET_TOKEN_EXPIRED_AT', response.data.expired_at)
          setTokenExpiredAt(response.data.expired_at)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // Забыл пароль->заявка на сброс
    applyForPasswordReset ({ commit }, userInfo) {
      const { email } = userInfo
      return new Promise((resolve, reject) => {
        applyForPasswordReset(email.trim()).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // Забыл пароль->подтвердить сброс пароля
    confirmPasswordReset ({ commit }, userInfo) {
      const { email, confirmCode } = userInfo
      return new Promise((resolve, reject) => {
        confirmPasswordReset(email, confirmCode.trim()).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    regenerateAccessToken ({ commit }) {
      return new Promise((resolve, reject) => {
        regenerateAccessToken().then(response => {
          commit('SET_TOKEN', response.data.token)
          commit('SET_TOKEN_EXPIRATION', response.data.expiration)
          commit('SET_TOKEN_EXPIRED_AT', response.data.expired_at)
          setToken(response.data.token)
          setTokenExpiration(response.data.expiration)
          setTokenExpiredAt(response.data.expired_at)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetUserInfo (context, href = '/api/auth/access/info') {
      context.commit('SET_TOKEN', getToken())
      context.commit('SET_TOKEN_EXPIRATION', getTokenExpiration())
      context.commit('SET_TOKEN_EXPIRED_AT', getTokenExpiredAt())

      return fetch(href, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      }).then(response => {
        if (response.status !== 200) {
          context.dispatch('FedLogout')
          throw new Error('Не удалось получить информацию пользователя по токену')
        }
        return response.json()
      }).then(data => {
        if (!data) {
          throw new Error('request doesn\'t return data')
        }
        if (data.roles && data.roles.length > 0) {
          context.commit('SET_ROLES', data.roles)
          context.commit('SET_PERMISSIONS', data.permissions)
          permissionService.user = data
          context.commit('SET_PROFILE_FIO', data.fio) // для компонента профиля
          context.commit('SET_ID', data.id)
          context.commit('SET_NEED_MANUALLY_CHANGE_PASSWORD', data.need_manually_change_password)

          window.dispatchEvent(authenticatedUserChecksPassedEvent)
        } else {
          throw new Error('getInfo: roles must be a non-null array !')
        }
        return data
      })
    },

    FedLogout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', undefined)
        commit('SET_TOKEN_EXPIRATION', undefined)
        commit('SET_TOKEN_EXPIRED_AT', undefined)
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        removeTokenExpiration()
        removeTokenExpiredAt()
        resolve()
      })
    },
    UpdateSettings ({ state }) {
      window.localStorage.setItem(SettingsKey, JSON.stringify(state.settings))
    },
    panelInit ({ commit }) {
      commit('SET_PANEL_STATE', true)
    },
    panelError ({ commit }) {
      commit('SET_PANEL_INIT_ERROR', true)
    }
  }
}

export default user
