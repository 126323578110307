import request from '@/utils/request'

export function update (id, data) {
  return request({
    url: '/api/system-configs/' + id,
    method: 'put',
    data
  })
}

export function get (data) {
  return request({
    url: '/api/system-configs',
    method: 'get',
    data
  })
}

export function getHelpInfo (data) {
  return request({
    url: '/api/help-info',
    method: 'get',
    data
  })
}
