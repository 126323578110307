import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { getToken, regenerateAccessTokenIfNeeded } from '@/utils/userAuth'
import router from '@/router'

const request = axios.create({
  headers: {
    post: {
      Accept: 'application/json'
    },
    common: {
      Accept: 'application/json'
    }
  },
  baseURL: window.location.origin,
  timeout: 30000
})

let routeFromRequestBeenSent = window.location.pathname

request.interceptors.request.use(
  config => {
    routeFromRequestBeenSent = window.location.pathname

    if (store.getters.token) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  async response => {
    await regenerateAccessTokenIfNeeded()

    return response
  },
  error => {
    if (error.response.data instanceof ArrayBuffer) {
      const data = String.fromCharCode.apply(null, new Uint8Array(error.response.data))
      const message = JSON.parse(data)?.message ?? ''
      ElMessage({
        message,
        type: 'error',
        customClass: 'response-err-message',
        duration: 4500
      })
    } else if (error.response.data instanceof Blob) {
      const reader = new FileReader()
      reader.onload = e => {
        ElMessage({
          message: JSON.parse(e.target.result).message,
          type: 'error',
          duration: 4500
        })
      }
      reader.readAsText(error.response.data)
    } else {
      if (error.response.status === 503) {
        router.push('/service')
      } else if (error.response.status === 401) {
        router.push({ name: 'registration', query: { redirect: routeFromRequestBeenSent } })
      } else {
        let message = error.response.data.message
        if (!message && Array.isArray(error.response.data)) {
          message = error.response.data.map(x => x.message).join('\n')
        }
        ElMessage({
          message,
          type: 'error',
          customClass: 'response-err-message',
          duration: 4500
        })
      }
    }
    return Promise.reject(error)
  }
)

export default request
