import Layout from '@/views/layout/Layout'

export default {
  path: '/personal',
  name: 'personal',
  component: Layout,
  redirect: '/personal/notifications',
  meta: {
    title: 'Личный кабинет',
    permissions: []
  },
  children: [
    {
      path: '/personal/notifications',
      component: () => import('@/views/personal/notifications/list'),
      name: 'notificationsList',
      meta: {
        title: 'Уведомления',
        permissions: []
      }
    },
    {
      path: '/personal/requests',
      component: () => import('@/views/personal/requests/list'),
      name: 'personalRequestsList',
      meta: {
        title: 'Запросы по КНМ',
        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperPersonalRequests',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/personal/requests',
      meta: {
        title: 'Запросы по КНМ'
      },
      children: [
        {
          path: '/personal/requests/edit/:id(\\d+)',
          component: () => import('@/views/journals/measureKnm/measureDocs/mdrEdit.vue'),
          name: 'personalRequestsEdit',
          hidden: true,
          meta: {
            title: 'Просмотр',
            permissions: []
          }
        }
      ]
    }
  ]
}
