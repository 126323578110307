import Cookies from 'js-cookie'

const app = {
  namespaced: true,
  state: {
    dateFormat: 'DD.MM.YYYY',
    dateValueFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
    dateTimeValueFormat: 'YYYY-MM-DDTHH:mm:ss',
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop',
    language: 'ru',
    size: Cookies.get('size') || 'medium',
    user: {
      regEmailconfirmationCode: '',
      regEmail: '',
      forgotPasswordEmail: ''
    }
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    TOGGLE_REG_EMAIL_CONFIRMATION_CODE: (state, code) => {
      state.user.regEmailconfirmationCode = code
    },
    TOGGLE_REG_EMAIL: (state, email) => {
      state.user.regEmail = email
    },
    TOGGLE_FORGOT_PASSWORD_EMAIL: (state, email) => {
      state.user.forgotPasswordEmail = email
    }
  },
  actions: {
    toggleSideBar ({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar ({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice ({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    toggleRegEmailConfirmationCode ({ commit }, code) {
      commit('TOGGLE_REG_EMAIL_CONFIRMATION_CODE', code)
    },
    toggleRegEmail ({ commit }, email) {
      commit('TOGGLE_REG_EMAIL', email)
    },
    toggleForgotPasswordEmail ({ commit }, email) {
      commit('TOGGLE_FORGOT_PASSWORD_EMAIL', email)
    }
  }
}

export default app
