// Карточка Правовой акт
export const LEGAL_ACT_FULL_ACCESS = 'legal_act_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const LEGAL_ACT_READ = 'legal_act_read' // Чтение всех Правовой акт
export const LEGAL_ACT_CREATE = 'legal_act_create'
export const LEGAL_ACT_EDIT = 'legal_act_edit'
export const LEGAL_ACT_DELETE = 'legal_act_delete'
export const LEGAL_ACT_RESTORE = 'legal_act_restore'
export const LEGAL_ACT_EDIT_RESP_IOGV = 'legal_act_edit_resp_iogv'
export const LEGAL_ACT_DELETE_RESP_IOGV = 'legal_act_delete_resp_iogv'
export const LEGAL_ACT_RESTORE_RESP_IOGV = 'legal_act_restore_resp_iogv'

export const permissions = {
  create: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_CREATE],
  edit: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_EDIT, LEGAL_ACT_EDIT_RESP_IOGV],
  delete: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_DELETE, LEGAL_ACT_DELETE_RESP_IOGV],
  restore: [LEGAL_ACT_FULL_ACCESS, LEGAL_ACT_RESTORE, LEGAL_ACT_RESTORE_RESP_IOGV]
}
