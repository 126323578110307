export default {
  name: 'ArrayErrorsToString',
  methods: {
    getPreparedErrorMessage (error, lineSeparator = '<br>') {
      let errorMessage = error.response.data?.message || error.message
      const errorsEntries = Object.entries(error.response.data?.errors || {})
      if (!errorsEntries.length) {
        return errorMessage
      }

      errorMessage = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of errorsEntries) {
        if (!Array.isArray(value)) {
          errorMessage.push(this.prettyErrorMessage(value))
          continue
        }

        value.forEach(v => {
          errorMessage.push(this.prettyErrorMessage(v))
        })
      }

      return errorMessage.join(lineSeparator)
    },
    prettyErrorMessage (value) {
      return value.replace(/[^:]+:\s+/, '')
    }
  }
}
