<template>
  <el-drawer
    v-model="show"
    title="Помощь"
    direction="rtl">
    <el-form class="my-3">
      <el-input
        v-model="config.value_string"
        :disabled="!canEdit"
        :autosize="{ minRows: 20, maxRows: 20 }"
        type="textarea" />
    </el-form>
    <el-row justify="end">
      <el-button @click="show = false">
        Отменить
      </el-button>
      <ElButton
        v-if="canEdit"
        type="primary"
        @click="save">
        Сохранить
      </ElButton>
    </el-row>
  </el-drawer>
</template>

<script>
import { get, update } from '@/api/systemConfig'
import { ElNotification } from 'element-plus'
import { hasRoles } from '@/store/modules/permission'
import { Roles } from '@/services/roles'

export default {
  name: 'HelpInfoDrawer',
  props: {
    modelValue: { type: Boolean, default: false }
  },
  data () {
    return {
      config: {
        id: '',
        code: '',
        name: '',
        type: '',
        value_string: '',
        value_bool: '',
        value_array: ''
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    },
    canEdit () {
      const roles = this.$store.state.user.roles

      return hasRoles(roles, [Roles.ROLE_ADMIN])
    }
  },
  created () {
    this.getHelpConfig()
  },
  methods: {
    getHelpConfig () {
      const params = {
        code: 'help'
      }

      get(params).then(({ data }) => {
        this.config = data[0]
      })
    },
    save () {
      update(this.config.id, this.config).then(() => {
        ElNotification({ title: 'Сохранено', type: 'success' })
      }).catch(() => {
        ElNotification({ title: 'Ошибка при сохранении', type: 'error' })
      })
    }

  }
}
</script>

<style scoped>

</style>
