import { RouterView } from 'vue-router'
import { USER_ADMINISTRATION_FULL_ACCESS } from '@/services/permissions/usersPermissions'
import { ANYTHING } from '@/services/permissions/PermissionService'

export const users = {
  path: 'users',
  component: RouterView,
  name: 'usersWrap',
  redirect: '/administration/users/list',
  meta: {
    permissions: [ANYTHING, USER_ADMINISTRATION_FULL_ACCESS]
  },
  children: [
    {
      path: '/administration/users/list',
      component: () => import('@/views/administration/users/usersList'),
      name: 'usersList',
      hidden: true,
      meta: {
        title: 'Журнал пользователей',
        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperUsers',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/administration/users/list',
      meta: {
        title: 'Журнал пользователей',
        permissions: []
      },
      children: [
        {
          path: '/administration/users/create',
          component: () => import('@/views/administration/users/usersEdit'),
          name: 'userCreate',
          hidden: true,
          meta: {
            title: 'Создание пользователя',
            permissions: []
          }
        },
        {
          path: '/administration/users/edit/:id(\\d+)',
          component: () => import('@/views/administration/users/usersEdit'),
          name: 'userEdit',
          hidden: true,
          meta: {
            title: 'Редактирование пользователя',
            permissions: []
          }
        }
      ]
    }
  ]
}
