<template>
  <div
    :class="classObj"
    class="app-wrapper">
    <div
      v-if="device==='mobile'&&sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside" />
    <sidebar
      class="sidebar-container"
      :routes="routes" />
    <div class="main-container">
      <navbar />
      <app-main v-if="isPanelInit" />
      <div
        v-else
        v-loading="!isPanelInit"
        style="width: 100%;height: 100%;" />
    </div>
    <user-change-password-dialog :prop-visible="dialogUserChangePasswordVisible" />
  </div>
</template>

<script>
import '@/styles/index.scss'
import 'element-plus/theme-chalk/dark/css-vars.css'
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { constantRouterMap } from '@/router'
import store from '@/store'
import UserChangePasswordDialog from '@/views/components/UserChangePasswordDialog.vue'

export default {
  name: 'LayoutView',
  components: {
    UserChangePasswordDialog,
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  props: {
    pathName: {
      type: String,
      default: ''
    }
  },
  computed: {
    routes () {
      return this.filterRouter(constantRouterMap)
    },
    sidebar () {
      return this.$store.state.app.sidebar
    },
    device () {
      return this.$store.state.app.device
    },
    classObj () {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    isPanelInit () {
      return this.$store.state.user.isPanelInit
    },
    dialogUserChangePasswordVisible () {
      return this.$store.state.user.needManuallyChangePassword
    }
  },
  methods: {
    handleClickOutside () {
      this.$store.dispatch('closeSideBar', { withoutAnimation: false })
    },
    filterRouter (routes) {
      const res = []

      routes.forEach(route => {
        const tmp = { ...route }
        if (this.hasPermission(store.getters.permissions, tmp)) {
          if (tmp.children) {
            tmp.children = this.filterRouter(tmp.children)
          }
          res.push(tmp)
        }
      })

      return res
    },
    hasPermission (roles, route) {
      if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
      } else {
        return true
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/styles/mixin.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
