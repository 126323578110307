import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import { authenticatedUserChecksPassedName } from '@/utils/customEvents'
import store from '@/store'
import { getTimestampInSeconds } from '@/utils/date'

let regenerateAccessTokenRequestIsPending = false

export function getToken () {
  return Cookies.get('aistoken')
}

export function setToken (token) {
  return Cookies.set('aistoken', token)
}

export function removeToken () {
  return Cookies.remove('aistoken')
}

export function getTokenExpiration () {
  return Cookies.get('aistoken_expiration')
}

export function setTokenExpiration (value) {
  return Cookies.set('aistoken_expiration', value)
}

export function getTokenExpiredAt () {
  return Cookies.get('aistoken_expirated_at')
}

export function setTokenExpiredAt (value) {
  return Cookies.set('aistoken_expirated_at', value)
}

export function removeTokenExpiration () {
  return Cookies.remove('aistoken_expiration')
}

export function removeTokenExpiredAt () {
  return Cookies.remove('aistoken_expirated_at')
}

export async function authInit (store, router) {
  console.log('Инициализация')

  // Подписаться на событие
  window.addEventListener(authenticatedUserChecksPassedName, authenticatedUserChecksPassed)

  async function authenticatedUserChecksPassed (event) {
    await initUserData(store, router, getToken())

    // Отписаться от события
    window.removeEventListener(authenticatedUserChecksPassedName, authenticatedUserChecksPassed)
  }
}

async function initUserData (store, router, aistoken) {
  try {
    await store.dispatch('user/panelInit')
    setToken(aistoken)
    store.dispatch('dictionary/GetAllDictionaries')
  } catch (err) {
    removeToken()
    await store.dispatch('user/panelInit')
    if (err.response.status === 500 || err.response.status === 502) {
      console.error(err)
      await router.push({ name: '500' })
    } else if (err.response.data.code === 401 || err.response.data.code === 403) {
      try {
        await router.push({ name: 'denied' })
      } catch (e) {
        console.warn('can\'t push denied, you are on denied')
      }
    } else {
      await store.dispatch('user/FedLogout')
      ElMessage.error(err || 'Ошибка верификации, пожалуйста попробуйте ещё раз.')
    }
  }
}

export async function regenerateAccessTokenIfNeeded () {
  if (regenerateAccessTokenRequestIsPending) {
    return
  }

  // Если токен скоро устареет - т.е. осталось 10 минут до его истечения
  const now = getTimestampInSeconds()
  const end = store.state.user.token_expired_at

  if (now >= end - store.state.user.token_expiration_notify_before_sec && now < end) {
    // "скоро токен устареет" => продлеваем удовольствие
    regenerateAccessTokenRequestIsPending = true
    await store.dispatch('user/regenerateAccessToken').finally(() => {
      regenerateAccessTokenRequestIsPending = false
    })
  }
}
