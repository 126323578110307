<template>
  <el-dialog
    v-if="helpers.visible"
    v-model="helpers.visible"
    title="Пожалуйста, придумайте пароль, он должен:"
    class="form-dialog"
    :before-close="handleBeforeClose"
    :show-close="false"
    width="30%">
    <el-form
      ref="form"
      v-loading="helpers.loading"
      :rules="rules"
      :model="form"
      label-width="auto">
      <el-row class="mb-4">
        <el-col :span="24">
          - Быть не менее 8 символов;<br>
          - Быть не более 40 символов;<br>
          - Содержать как минимум по одному символу в нижнем и верхнем регистрах;<br>
          - Содержать минимум один спец символ;<br>
          - Содержать минимум одну цифру;
        </el-col>
      </el-row>
      <el-form-item
        label="Новый пароль"
        prop="password">
        <el-input
          v-model="form.password"
          type="password"
          autocomplete="off"
          show-password/>
      </el-form-item>
      <el-form-item
        label="Повторите новый пароль"
        prop="password_confirmation"
        class="mb-4">
        <el-input
          v-model="form.password_confirmation"
          type="password"
          autocomplete="off"
          show-password/>
      </el-form-item>
      <el-row justify="center">
        <ElButton
          type="primary"
          @click="submit">
          Сохранить
        </ElButton>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { ElNotification } from 'element-plus'
import { changePassword } from '@/api/user'
import ArrayErrorsToString from '@/mixins/forms/errors/ArrayErrorsToString'

export default {
  name: 'UserChangePasswordDialog',
  mixins: [ArrayErrorsToString],
  props: {
    propVisible: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      helpers: {
        visible: this.$props.propVisible,
        loading: false
      },
      form: {
        password: null,
        password_confirmation: null
      },
      rules: {
        password: [{ required: true, trigger: ['blur', 'change'], message: 'Введите пароль' }],
        password_confirmation: [{ required: true, trigger: ['blur', 'change'], message: 'Введите подтверждение пароля' }]
      }
    }
  },
  watch: {
    propVisible (newVal) {
      this.toggleDialog(newVal)
    }
  },
  methods: {
    handleBeforeClose (doneFn) {
      // Ничего не делаем чтобы окно не закрывалось!
    },
    toggleDialog (val) {
      this.helpers.visible = val
    },
    showLoading (value) {
      this.helpers.loading = value
    },
    submit () {
      this.$refs.form.clearValidate()
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          ElNotification({ title: 'Проверьте правильность заполнения полей', type: 'error' })
          console.warn(errors)
          return false
        }

        this.showLoading(true)
        changePassword(this.form).then(({ data }) => {
          ElNotification({ title: 'Сохранено', type: 'success' })

          this.$store.state.user.needManuallyChangePassword = false

          this.toggleDialog(false)
        }).catch((error) => {
          this.$notify({
            title: 'Ошибка',
            customClass: 'notificaton-error',
            dangerouslyUseHTMLString: true,
            message: this.getPreparedErrorMessage(error),
            type: 'error',
            duration: 4000
          })
        }).finally(() => {
          this.showLoading(false)
        })
      })

      return false
    }
  }
}
</script>
