export const RequiredMessage = 'Атрибуты, отмеченный звёздочкой (*), обязательны для заполнения'
export const pagerLayout = 'prev, pager, next, sizes, total'

export const trashedOptions = [
  { id: 'with_trashed', name: 'Все' },
  { id: 'without_trashed', name: 'Без удаленных' },
  { id: 'only_trashed', name: 'Только удаленные' }
]

export const pageSizes = [10, 50, 100, 1000]

export const planKnmTypes = ['doc_plan', 'visit_plan']

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {}

export const knmStatusInWork = 'in_work'
export const knmStatusComplete = 'complete'
export const knmStatusCancel = 'cancel'
export const knmStatusImpossible = 'impossible'

export const knmRegardingsOtherCode = 'other_questions'

export const filtersEventName = 'need-apply-filters'

// Публичная часть
export const EmployeeAffiliationIogv = 'iogv'
export const EmployeeAffiliationSuborg = 'suborg'
