import { allDictionary } from '@/api/dictionary'

const dictionary = {
  namespaced: true,

  state: {
    employee_categories: [],
    employee_subcategories: [],
    enum_applied: [],
    enum_knm_act_result: [],
    enum_knm_act_status: [],
    enum_knm_status: [],
    enum_mdr_status: [],
    enum_plan_agreed_stage: [],
    enum_plan_status: [],
    enum_violation_status: [],
    enum_yes_no: [],
    industry_affiliation: [],
    knm_base: [],
    knm_regardings: [],
    knm_regardings_flat: [],
    knm_subject: [],
    knm_type: [],
    positions: [],
    taken_measures: [],
    target: []
  },

  getters: {},

  mutations: {
    SET_DICTIONARY: (state, dictionary) => {
      state[dictionary.dictionaryName] = dictionary.values
    }
  },

  actions: {
    GetAllDictionaries ({ commit }) {
      return new Promise(resolve => {
        allDictionary().then(({ data }) => {
          for (const [key, value] of Object.entries(data)) {
            commit('SET_DICTIONARY', { dictionaryName: key, values: value })
          }
          resolve()
        })
      })
    }
  }
}

export default dictionary
